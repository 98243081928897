import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import SoftwareSec from "../components/training-webinars/training-webinars"
import Hero from "../components/training-webinars/hero.js"

const TrainingWebinars = ( {data}) => (
  <div className="contactPage softwareAccess trainingWebinars trainingWebinarsPage">
    <Layout>
      <Seo title="Training Webinars" />
      <div className="contactSecWrapper trainingSec">
        <Hero data={data.contentfulTrainingWebinars} />
        <SoftwareSec data={data.contentfulTrainingWebinars}/>
      </div>
    </Layout>
  </div>
)

export default TrainingWebinars

export const query = graphql`
  query TrainingWebinarsQuery($language: String) {
    contentfulTrainingWebinars(node_locale: { eq: $language }) {
      title
      trainingImage {
        url
      }
      inventoryManagementTrainingImage {
        url
      }
      inventoryManagementTrainingDates {
        raw
      }
      trainingDates {
        raw
      }
    }
  }
`