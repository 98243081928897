import React from "react"

const Hero = ({ data }) => {
    const { title } = data
    return (
        <div className="common_banner_section software">
            <div className="container">
                <div className="banner_center_section">
                    <h1>{title}</h1>
                    <div className="banner_info"></div>
                </div>
            </div>
        </div>
    )
}

export default Hero