import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

const TrainingWebinars = ({ data }) => {
    const { trainingImage, trainingDates, inventoryManagementTrainingImage, inventoryManagementTrainingDates } = data

    // Custom rendering options for Contentful Rich Text
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => (
                <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            ),
            // Add other custom renderings for different node types if needed
        },
    }

    return (
        <section className="trainingWebinarSec">
            <div className="trainingWebinarsWrapper">
            <img src={inventoryManagementTrainingImage.url} className="roadmapTrianingWebinar"/>
                <div className="trainWebinarsLinkSec">
                    {inventoryManagementTrainingDates && renderRichText(inventoryManagementTrainingDates, options)}
                </div>
            </div>
            <div className="trainingWebinarsWrapper">
            <img src={trainingImage.url} className="roadmapTrianingWebinar"/>
                <div className="trainWebinarsLinkSec">
                    {trainingDates && renderRichText(trainingDates, options)}
                </div>
            </div>
        </section>
    )
}

export default TrainingWebinars
